/* .home-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(6, 42, 95, 0.8);
    padding: 1rem;
  }
  
  nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  nav ul li {
    margin: 0 1rem;
  }
  
  nav ul li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
  }
  
  main {
    flex-grow: 1;
  }
  
  footer {
    background-color: #062a5f;
    color: white;
    text-align: center;
    padding: 1rem;
  }*/

  .Intro-home-para {
    font-family: 'Graphik', sans-serif;
    margin: 0 auto;
    padding: 20px;
    
  }
  
  .Intro-home-para h2 {
    color: #003366;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 15px;
    padding-left: 40px;
  }
  
  .Intro-home-para p {
    color: #333333;
    font-size: 18px;
    line-height: 1.5;
    padding-left: 40px;
    text-align: justify;
    padding-right: 40px;
    font-weight: 600;
  }
  
  @media (max-width: 768px) {
  .standardhome{
    display: none;
  }
  } 