@font-face {
  font-family: 'Graphik';
  src: url('../../fonts/GraphikRegular.otf') format('opentype');
}

@font-face {
  font-family: 'GraphikLight';
  src: url('../../fonts/GraphikLight.otf') format('opentype');
}
.contact-us7 {
  font-family: 'Graphik', sans-serif;
  position: relative;
}

.breadcrumb7 {
  padding: 10px 20px;
  background-color: #f1f1f1;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
}

.content56 {
  height: 72%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay6 {
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 30px;
  background: linear-gradient(135deg, #005288 30%, #f85f36 100%);
  opacity: 0.9;
}

.contact-container2 {
  text-align: center;
  max-width: 600px;
  width: 90%;
  
}

.contact-container2 h1 {
  margin-bottom: 20px;
  font-size: 2.6em;
  color: white;
  transition: opacity 1s ease;
  animation-delay: 1s;
  animation: fadeInUp 1s ease-out forwards;
}

.contact-container2 p {
  margin-bottom: 20px;
  font-size: 1.4em;
  animation-delay: 1s;
  animation: fadeInUp 1s ease-out forwards;
  transition: opacity 1s ease;
  color: white;
}

.faq-button {
  background-color: #f85f36;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  
}

.faq-button a {
  color: white;
  text-decoration: none;
}

.faq-button:hover {
  background-color: #f85f36;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.support-info-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f5f5f5;
  position: relative;
}

.support-info {
  font-family: 'Graphik', sans-serif;
  max-width: 1240px;
  margin: 0 auto;
  padding: 10px;
  padding-top: 25px;
  color: #333;
  position: relative;
}

.support-info h1 {
  color: #003366;
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 20px;
  text-align: justify;
}

.availability {
  margin-bottom: 50px;
}

.support-unavailable {
  border: 1px solid #ddd;
  border-left: 5px solid #f85f36;
  background-color: #fff;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.support-unavailable.expanded {
  background-color: #E5EFF3;
}

.support-unavailable h2 {
  color: #003366;
  font-size: 20px;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 29px;
}

.arrow {
  border: solid #333;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.3s ease;
}

.arrow.down {
  transform: rotate(45deg);
}

.arrow.up {
  transform: rotate(-135deg);
}

.content1 {
  padding: 0 15px 15px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.support-unavailable.expanded .content1 {
  max-height: 500px;
  padding: 15px 15px;
}

.support-unavailable p {
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: 19px;
  text-align: justify;
  padding-right: 70px;
  padding-left: 10px;
}

.content1 a {
  color: #0066cc;
  text-decoration: none;
}

.content1 a:hover {
  text-decoration: underline;
}

.contact-info {
  font-family: 'Graphik', sans-serif;
  max-width: 1240px;
  margin: 0 auto;
  padding: 10px;
  margin-top: 0;
}

.contact-section {
  margin-bottom: 30px;
}

.contact-section h2 {
  color: #003366;
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-section p {
  font-size: 16px;
  line-height: 1.5;
}

.contact-section a {
  color: #0066cc;
  text-decoration: none;
}

.contact-section a:hover {
  text-decoration: underline;
}

.careers-link {
  color: #0066cc;
  text-decoration: underline;
}

.map {
  padding-top: 25px;
}

.map iframe {
  width: 100%;
  height: 450px;
  border: 0;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .contact-info, .support-info {
      width: 90%;
      padding: 10px;
  }

  .breadcrumb {
      font-size: 13px;
  }

  .contact-container h1 {
      font-size: 2.2em;
  }

  .contact-container p {
      font-size: 1.3em;
  }
}

@media (max-width: 768px) {
  .contact-container h1 {
      font-size: 2em;
  }

  .contact-container p {
      font-size: 1.2em;
  }

  .support-info, .contact-info {
      width: 95%;
      padding: 10px;
  }

  .support-info h1 {
      font-size: 20px;
  }

  .support-unavailable h2 {
      font-size: 16px;
  }

  .support-unavailable p {
      font-size: 14px;
      padding-right: 10px;
      padding-left: 10px;
  }

  .contact-section h2 {
      font-size: 20px;
  }

  .contact-section p {
      font-size: 14px;
  }

  .contact-section{
    overflow: hidden;
  }

  .map {
      height: 500px;
  }
}

@media (max-width: 480px) {
  .breadcrumb {
      font-size: 12px;
      padding: 5px 10px;
  }

  .faq-button {
      padding: 8px 15px;
      font-size: 1em;
  }

  .support-unavailable h2 {
      font-size: 14px;
  }

  .support-unavailable p {
      font-size: 12px;
  }

  .contact-section h2 {
      font-size: 18px;
  }

  .contact-section p {
      font-size: 12px;
  }

  .map {
      height: 450px;
  }
}
