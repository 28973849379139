.containerNews {
    padding: 20px;
  }
  
  .event-sectionNews {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }
  
  .event-card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    width: 300px;
    /* width: calc(50% - 10px); */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .event-image {
    width: 100%;
    height: 200px;
  }
  
  .event-info {
    padding: 15px;
  }
  .register-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #f85f36;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .register-button:hover {
    background-color: #e54e2a;
  }
  .event-label {
    display: inline-block;
    background-color: #f85f36;
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    margin-bottom: 10px;
  }
  
  .event-info h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
    /* width: 60%; */
  }
  
  .event-info p {
    font-size: 14px;
    color: #666;
    /* width: 60%; */
  }
  
  .case-studies-section {
    display: flex;
    flex-wrap: wrap;
  }
  
  .case-studies-card {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .case-studies-image {
    width: 50%;
    height: auto;
  }
  
  .case-studies-info {
    padding: 15px;
  }
  
  .case-studies-info h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .case-studies-info p {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .case-studies-link {
    font-size: 14px;
    color: #007bff;
    text-decoration: none;
  }
  
  .case-studies-link:hover {
    text-decoration: underline;
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .event-sectionNews {
      flex-direction: column;
      gap: 10px;
    }
  
    .event-card {
      width: 100%;
    }
  
    .case-studies-section {
      flex-direction: column;
    }
  
    .case-studies-card {
      flex-direction: column;
    }
  
    .case-studies-image {
      width: 100%;
    }
  
    .case-studies-info {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .event-info h3, .case-studies-info h3 {
      font-size: 16px;
    }
  
    .event-info p, .case-studies-info p {
      font-size: 12px;
    }
  
    .case-studies-link {
      font-size: 12px;
    }
  }
  