.verified-by-gs1-container {
    /* max-width: 1200px; */
    margin: 0 auto;
    /* padding: 20px; */
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    color: #003399;
    margin-bottom: 20px;
  }
  
  .iframe-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 aspect ratio */
  }
  
  .iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  
  @media (max-width: 768px) {
    .verified-by-gs1-container {
      padding: 10px;
    }
  
    h1 {
      font-size: 24px;
    }
  }