  
  @font-face {
    font-family: Hagrid;
    src: url(../../../fonts/Hagrid-Regular-trial.ttf);
  }
  
  @font-face {
    font-family: Graphik;
    src: url(../../../fonts/GraphikRegular.otf);
  }
  
  @font-face {
    font-family: GraphikLight;
    src: url(../../../fonts/GraphikLight.otf);
  }
  
  .container1 {
    position: relative;
    width: 100%;
    /* max-width: 1200px; */
    height: 50vh;
    margin-bottom: 30px;
  }
  
  .background-image1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }
  
  .overlayBarcode {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* padding-left: 50px; */
    background: rgba(0, 0, 0, 0.5); 
  }
  
  .text-section1 {
    padding: 40px;
    width: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: white;
    font-family: Graphik;
  }
  
  .text-section1 h1 {
    font-size: 40px;
    margin-bottom: 20px;
    font-family: Graphik;
    color: white;
    text-align: left;
  }
  
  .text-section1 p {
    font-size: 22px;
    margin-bottom: 30px;
    color: #f0f0f0;
    font-family: GraphikLight;
  }
  
  .cta-button1 {
    display: inline-block;
    padding: 15px 30px;
    background-color: #f85f36;
    color: white;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    font-size: 19px;
    cursor: pointer;
    font-family: GraphikLight;
  }
  
  .cta-button1:hover {
    background-color: #f85f36;
  }

  @media (max-width: 1024px) {
    .container1 {
      flex-direction: column;
      height: 30vh;
    }
  
    .text-section1,
    .image-section1 {
      width: 100%;
    }
  
    .text-section1 {
      white-space: normal;
    }
  }
  
  @media (max-width: 480px) {
    .text-section1 h1 {
      font-size: 24px;
      color: white;
    }
  
    .text-section1 p {
      font-size: 16px;
    }
  
    .cta-button1 {
      font-size: 16px;
      padding: 10px 20px;
    }
  }
  