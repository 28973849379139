@font-face {
  font-family: 'Graphik';
  src: url('../../fonts/GraphikRegular.otf') format('opentype');
}

@font-face {
  font-family: 'GraphikLight';
  src: url('../../fonts/GraphikLight.otf') format('opentype');
}

.barcode-container {
  width: 100%;
  height: 380px;
  background-size: cover;
  background-position: center;
  position: relative;
  color: white;
  font-family: 'Graphik', sans-serif;
  overflow: hidden;
}

.overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #005288 30%, #f85f36 100%);
  opacity: 0.8;
}

.content-barcode {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
  margin-top: 50px;
}

.content-barcode h1 {
  font-size: 36px;
  margin-bottom: 10px;
  margin-top: 110px;
  color: white;
  padding-right: 130px;
}

.content-barcode h2 {
  font-size: 24px;
  font-weight: normal;
  padding-left: 50px;
  color: white;
  animation-delay: 0.3s;
  animation: fadeInUp 0.6s ease-out forwards;
  transition: opacity 0.3s ease;
}


.text-contain p {
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 30px;
  text-align: justify;
  color: black;
  font-family: 'Graphik', sans-serif;
  font-size: 20px;
  margin-bottom: 0px;

}


.body {
  font-family: 'Graphik', sans-serif;
  margin: 0;
  padding: 0;
}

.simple-container {
  width: 1200px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;

}

.simple-container h2 {
  color: #003366;
  font-size: 25px;
  text-align: left;
  margin-bottom: 5px;
  padding-left: 30px;
}

.steps-container {
  display: flex;
  justify-content: center;
  gap: 60px;
  width: 100%;
  margin-top: 30px;
}

.step {
  flex: 1;
  background-color: #ffffff;
  padding: 20px;
  width: 300px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 325px;
}

.image-container1 {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 15px;
}

.image-container1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.step h2 {
  color: #003366;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: left;
}

.step p {
  color: #333333;
  font-size: 14px;
  line-height: 1.5;
  text-align: justify;
  padding-left: 20px;
}

.body {
  font-family: 'Graphik', sans-serif;
  margin: 0;
  padding: 0;
}

.online-application {
  font-family: 'Graphik', sans-serif;
  width: 90%;
  margin: 0 auto;
  padding: 20px;
}

.online-application h1 {
  color: #003366;
  font-size: 28px;
  margin-bottom: 20px;
}

.description-online {
  color: #333;
  line-height: 1.5;
  margin-bottom: 20px;

}

.membership-option {
  background-color: #f0f8ff;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.membership-option h2 {
  color: #0066cc;
  font-size: 18px;
  margin-bottom: 10px;
}

.membership-option p {
  color: #333;
  line-height: 1.5;
  margin-bottom: 15px;
}


























.button-container {
  text-align: right;
  perspective: 1000px;
}

.apply-button {
  background-color: #f85f36;
  color: white;
  border: none;
  padding: 15px 40px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
}

/* Cyberpunk-style background effect */
.apply-button::before {
  content: '';
  position: absolute;
  inset: -5px;
  background: conic-gradient(from 0deg at 50% 50%,
      #f85f36,
      #ff8f75,
      #003cff,
      #405dff,
      #0026ff);
  animation: rotate 4s linear infinite;
  z-index: -2;
}

/* Inner panel effect */
.apply-button::after {
  content: '';
  position: absolute;
  inset: 3px;
  background: #f85f36;
  z-index: -1;
  clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
}

/* Floating particles */
.apply-button span {
  position: absolute;
  pointer-events: none;
}

.apply-button span:nth-child(1) {
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(transparent 0%,
      rgba(255, 255, 255, 0.1) 1%,
      transparent 2%);
  animation: scanline 2s linear infinite;
}

/* Glitch effect on hover */
.apply-button:hover {
  animation: glitch 0.3s cubic-bezier(.25, .46, .45, .94) both infinite;
  color: #ffffff;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
}

.apply-button:hover::after {
  background: #ff3300;
}



.button-container:hover::before,
.button-container:hover::after {
  opacity: 10;
  transform: scaleX(1.5);
}

/* Animations */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

































.final-container {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
  color: white;
  font-family: 'Graphik', sans-serif;
  overflow: hidden;
  margin-top: 50px;
}

.over2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #005288 30%, #f85f36 100%);
  opacity: 0.8;
}

.content-final {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
}

.content-final h1 {
  font-size: 36px;
  margin-bottom: 10px;
  margin-top: 50px;
  color: white;
  padding-right: 680px;
}

.content-final p {
  font-size: 20px;
  font-weight: normal;
  padding-left: 50px;
  color: white;
  width: 920px;
  animation-delay: 0.3s;
  animation: fadeInUp 0.6s ease-out forwards;
  transition: opacity 0.3s ease;
}

.faq-button-final9 {
  background-color: #f85f36;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  animation-delay: 0.3s;
  animation: fadeInUp 0.6s ease-out forwards;
  transition: opacity 0.3s ease;
  margin-top: 10px;
  margin-left: 50px;
}

.faq-button-final9 a {
  color: white;
  text-decoration: none;
}

.faq-button-final9:hover {
  background-color: #f85f36;
}


@media (max-width: 1200px) {
  .simple-container {
    width: 90%;
  }
}

@media (max-width: 992px) {
  .barcode-container {
    height: auto;
    min-height: 290px;
    margin-top: 40px;
  }

  .content-barcode h1 {
    margin-top: 60px;
  }

  .steps-container {
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }

  .step {
    flex-basis: calc(50% - 30px);
    margin-bottom: 20px;
  }

  .online-application {
    width: 90%;
  }

  .description-online {
    text-align: justify;
  }

  .membership-option p {
    text-align: justify;
  }
}

@media (max-width: 768px) {

  .content-barcode,
  .content-final {
    padding: 15px;
  }

  .content-barcode h1,
  .content-final h1 {
    font-size: 28px;
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 20px;

  }

  .content-barcode h2,
  .content-final p {
    font-size: 18px;
    padding-left: 20px;
    text-align: justify;
  }

  .text-contain p {
    padding-left: 20px;
    padding-right: 20px;
  }

  .step {
    flex-basis: 100%;
    max-width: none;
  }

  .final-container {
    height: auto;
    min-height: 300px;

  }

  .content-final p {
    width: 90%;
  }
}

@media (max-width: 576px) {

  .content-barcode h1,
  .content-final h1 {
    font-size: 24px;
  }

  .content-barcode h2,
  .content-final p {
    font-size: 16px;
  }

  .text-contain p {
    font-size: 14px;
  }

  .simple-container h2 {
    font-size: 22px;
  }

  .step h2 {
    font-size: 18px;
  }

  .step p {
    font-size: 12px;
  }

  .membership-option h2 {
    font-size: 16px;
  }

  .apply-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 400px) {

  .content-barcode h1,
  .content-final h1 {
    font-size: 20px;
  }

  .content-barcode h2,
  .content-final p {
    font-size: 14px;
  }

  .faq-button-final9 {
    font-size: 0.8em;
  }
}