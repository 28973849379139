.footer {
  background-color: #f9f9f9;
  padding: 20px 100px 20px;
  font-family: Arial, sans-serif;
  box-shadow: 0 -5px 15px rgba(0,0,0,0.1);
}

.footer-top {
  display: flex;
 margin-left: -150px;
  justify-content: space-evenly;
  margin-bottom: 1px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 1px;
  align-items: center;
}


.footer-column:hover {
  transform: translateY(-5px);
}

.footer-logo {
  width: 120px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.footer-logo:hover {
  transform: scale(1.05);
}

.footer-column h4 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #f85f36;
  position: relative;
  padding-bottom: 10px;
  text-decoration: none;
}
.link{
  text-decoration: none;
}



.footer-column h4::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 2px;
  background-color: #003366;
  transition: width 0.3s ease;
}

.footer-column:hover h4::after {
  width: 50px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 8px;
  color: #003366;
  transition: transform 0.2s ease;
}

.footer-column ul li:hover {
  transform: translateX(5px);
}

.footer-column ul li a {
  color: #003366;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-column ul li a:hover {
  color: #0077b5;
}

.footer-middle {
  margin-bottom: 1px;
}

.social-links {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.social-links li {
  margin: 0 15px;
}

.social-links li a {
  display: block;
  transition: transform 0.3s ease;
}

.social-links li a:hover {
  transform: translateY(-5px) rotate(5deg);
}

.social-links a img {
  width: 30px;
  height: 30px;
  transition: all 0.3s ease;
}

.social-links a:hover img {
  filter: brightness(1.2);
}

.footer-bottom1 {
  text-align: center;
  color: #555;
  font-size: 14px;
}

.footer-links {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.footer-links li {
  margin: 5px 15px;
}

.footer-links li a {
  color: #555;
  text-decoration: none;
  transition: color 0.3s ease;
  position: relative;
}

.footer-links li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: #0077b5;
  transition: width 0.3s ease;
}

.footer-links li a:hover {
  color: #0077b5;
}

.footer-links li a:hover::after {
  width: 100%;
}

.footer-bottom1 p {
  color: #555;
  line-height: 1.6;
  /* max-width: 800px; */
  margin: 0 auto;
}

.footer-bottom1 p15 {
  
    color: #f85f36;
  
}

@media (max-width: 1200px) {
  .footer {
    padding: 20px 30px;
  }

  .footer-top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: center;
    margin-left: 5%;
  }

  .footer-column {
    margin: 0;
    text-align: center;
  }

  .footer-column:first-child {
    display: none; /* Hide the logo column */
  }

  .footer-column h4 {
    font-size: 16px;
    margin-bottom: 1px;
    position: relative;
    padding-bottom: 10px;
  }

  .footer-column h4::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 30px;
    height: 2px;
    background-color: #003366;
    transform: translateX(-50%);
  }

  .social-links {
    flex-wrap: wrap;
  }

  .social-links li {
    margin: 0 10px 10px;
  }

  .footer-bottom1 p {
    font-size: 12px;
  }
}