@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.video-container {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  filter: brightness(100%) contrast(110%);
}

.content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5rem;
  background: linear-gradient(to right, rgb(44, 56, 165), rgba(255, 255, 255, 0));
  color: #ffffff;
  font-family: 'Graphik', sans-serif;
}

.title111 {
  font-size: 4rem;
  font-weight: 600;
  /* margin-bottom: 1rem; */
  line-height: 1.2;
  color: #ffffff;

}

.highlight111 {
  background: linear-gradient(45deg, #f85f36, #ffee00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: solid;
  
}

.subtitle1 {
  font-size: 1rem;
  font-weight: 300;
  max-width: 700px;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.cta-button1 {
  background-color: #f85f36;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 0.8rem;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.cta-button:hover {
  background-color: #f85f36;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(255, 107, 107, 0.3);
}

@media (max-width: 768px) {
  .content-overlay {
    padding: 3rem;
  }

  .title {
    font-size: 3rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }
  
}

@media (max-width: 480px) {
  .content-overlay {
    padding: 0.5rem;
  }

  .title {
    font-size: 2.5rem;
  }

  .subtitle1 {
    font-size: 1rem;
    font-weight: 300;
    max-width: 350px;
    margin-bottom: 1rem;
    line-height: 1.6rem;
  }

  .subtitle {
    font-size: 1rem;
    width: 90%;
  }

  .cta-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
}