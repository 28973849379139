@font-face {
  font-family: Hagrid;
  src: url('../../../fonts/Hagrid-Regular-trial.ttf');
}

@font-face {
  font-family: Graphik;
  src: url('../../../fonts/GraphikRegular.otf');
}

@font-face {
  font-family: GraphikLight;
  src: url('../../../fonts/GraphikLight.otf');
}

.mainRetail-Retail {
  display: flex;
  flex-direction: row;
}

.retail-banner {
  background-image: url('../../../assets/retail.jpg'); /* Replace with your actual image path */
  background-size: cover;
  background-position: center;
  height: 400px; /* Adjust based on your needs */
  display: flex;
  align-items: center;
  padding: 0 50px;
  position: relative;
}

.retail-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity as needed */
}

.banner-content {
  position: relative;
  z-index: 1;
  color: white;
  max-width: 50%;
}

.banner-content h1 {
  font-size: 48px;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: Graphik;
  color: white;
}

.banner-content p {
  font-size: 18px;
  line-height: 1.5;
  font-family: Graphik;
}

.retail-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 20px 50px;
}

.retail-info {
  color: #333; /* Text color for the content below the banner */
  font-size: 20px; /* Font size to match the picture */
  line-height: 1.8; /* Line height to match the picture */
}

.retail-info h4 {
  margin-bottom: 20px; /* Space between paragraphs */
  color: #003e66;
  font-family: Graphik;
}

.retail-info p {
  margin-bottom: 20px; /* Space between paragraphs */
  font-family: GraphikLight;
}

.retail-images {
  width: 50%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.retail-images img {
  width: 150px;
  height: 150px;
  align-self: center;
}

.retail-images p {
  font-size: 18px;
  font-family: Graphik;
  color: #003e66;
  text-align: center;
  width: 60%;
}

.retail-benefits {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: translateY(0);
}

.benefit-item {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.benefit-item img {
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

.benefit-item p {
  font-size: 18px;
  font-family: Graphik;
  font-weight: 800;
  color: #003e66;
}

.additional-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 40px 50px;
}

.additional-image-item {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.additional-image-item img {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.additional-image-item p {
  font-size: 18px;
  font-family: Graphik;
  color: #003e66;
  text-align: center;
}

/* Media query for tablets */
@media (max-width: 768px) {
  .mainRetail-Retail{
    flex-direction: column;
    padding: 10px;
  }
  .retail-banner {
    padding: 0 20px;
  }

  .banner-content {
    max-width: 100%;
  }

  .banner-content h1 {
    font-size: 36px;
  }

  .banner-content p {
    font-size: 16px;
  }

  .retail-content {
    flex-direction: column;
    margin: 20px 0;
  }

  .retail-info {
    width: 100%;
    font-size: 18px; /* Adjust font size for smaller screens */
  }

  .retail-images{
    display: none;
  }

  .retail-benefits {
    flex-direction: column;
  }

  .benefit-item {
    width: 100%;
  }
}

/* Media query for small screens */
@media (max-width: 480px) {
  .banner-content h1 {
    font-size: 24px;
  }

  .banner-content p {
    font-size: 14px;
  }

  .retail-info {
    font-size: 16px;
  }

  .benefit-item {
    max-width: 100%;
  }

  .retail-images img {
    width: 120px;
    height: 120px;
  }

  .additional-image-item img {
    width: 80px;
    height: 80px;
  }

  .retail-images p,
  .benefit-item p,
  .additional-image-item p {
    font-size: 16px;
  }
}
