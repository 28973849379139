@font-face {
  font-family: 'Graphik';
  src: url('../../fonts/GraphikRegular.otf') format('opentype');
}

@font-face {
  font-family: 'GraphikLight';
  src: url('../../fonts/GraphikLight.otf') format('opentype');
}

.product-photography {
  font-family: 'Graphik', sans-serif;
  color: #333;
  background-color: #f8f9fa;
}

.about-us-container {
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
}

.overlay1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #005288 30%, #f85f36 100%);
  opacity: 0.9;
}

.content-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  padding: 0 20px;
  z-index: 2;
}

.content-wrapper h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  animation: fadeInDown 0.8s ease-out forwards;
}

.content-wrapper h2 {
  font-size: 1.8rem;
  font-weight: 300;
  color: white;
  opacity: 0.9;
  animation: fadeInUp 0.8s ease-out forwards;
}
.content23{
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #EFF0F3;

}
.content {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 60px 40px;
  background-color: white;
  /* box-shadow: 0 10px 30px rgba(0,0,0,0.1); */
  margin-top: -50px;
  position: relative;
  margin-bottom: 20px;
  z-index: 3;
  border-radius: 10px;
  width: 85%;
  /* border-bottom-right-radius: 10px; */
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.product-image {
  max-width: 360px;
  height: 360px;
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
  transition: transform 0.3s ease;
}

/* .product-image:hover {
  transform: scale(1.05);
} */

.benefits-list {
  list-style-type: none;
  padding: 0;
  flex: 1;
}

.benefits-list li {
  margin-bottom: 20px;
  position: relative;
  padding-left: 40px;
  font-size: 1.1rem;
  line-height: 1.6;
}

.benefits-list li::before {
  content: '✓';
  color: #4CAF50;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.4rem;
  background-color: #e8f5e9;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Tablet styles */
@media (max-width: 1024px) {
  .content-wrapper h1 {
    font-size: 2.8rem;
  }
  
  .content-wrapper h2 {
    font-size: 1.5rem;
  }
  
  .content {
    flex-direction: column;
    padding: 40px 20px;
  }
  
  .product-image {
    max-width: 50%;
    margin-bottom: 30px;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .content-wrapper h1 {
    font-size: 2.2rem;
  }
  
  .content-wrapper h2 {
    font-size: 1.3rem;
  }
  
  .benefits-list li {
    font-size: 1rem;
  }
  
  .product-image {
    max-width: 80%;
  }
}