@font-face {
    font-family: 'Graphik';
    src: url('../../fonts/GraphikRegular.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'GraphikLight';
    src: url('../../fonts/GraphikLight.otf') format('opentype');
  }
  
  .about-us-container {
    width: 100%;
    height: 350px;
    background-size: cover;
    background-position: center;
    position: relative;
    color: white;
    font-family: 'Graphik', sans-serif;
    overflow: hidden;
  }
  
  .overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(49, 49, 131, 0.95) 50%, transparent 100%);
  }
  
  .content-wrapper {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
  }
  
  .content-wrapper h1 {
    font-size: 36px;
    margin-bottom: 10px;
    margin-top: 110px;
    color: white;
    padding-left: 50px;
    animation-delay: 0.3s;
    animation: fadeInUp 0.6s ease-out forwards;
    transition: opacity 0.3s ease;
  }
  
  .content-wrapper h2 {
    font-size: 24px;
    font-weight: normal;
    padding-left: 50px;
    color: white;
    animation-delay: 0.3s;
    animation: fadeInUp 0.6s ease-out forwards;
    transition: opacity 0.3s ease;
  }
  
  .container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background-color: #f8f9fa;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .image-section,
  .text-section {
    flex: 1;
  }
  
  .image-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .background-image {
    width: 100%;
    height: auto;
  }
  
  .text-section {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    border-radius: 8px;
  }
  
  .text-section h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .text-section p {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
  }
  
  .text-section .date {
    font-size: 14px;
    color: #999;
    margin-top: 20px;
  }
  
  .text-section .read-more {
    font-size: 14px;
    color: #007bff;
    text-decoration: none;
  }
  
  .text-section .read-more:hover {
    text-decoration: underline;
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .about-us-container {
      height: auto;
      background-position: top center;
    }
  
    .content-wrapper h1 {
      font-size: 28px;
      padding-left: 20px;
      margin-top: 50px;
    }
  
    .content-wrapper h2 {
      font-size: 20px;
      padding-left: 20px;
    }
  
    .container {
      flex-direction: column;
    }
  
    .image-section {
      order: 1;
    }
  
    .text-section {
      order: 2;
      padding: 15px;
    }
    .background-image {
      width: 100%;
      height: auto;
      margin-top: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .about-us-container {
      height: auto;
      background-position: top center;
    }
  
    .content-wrapper h1 {
      font-size: 24px;
      padding-left: 10px;
    }
  
    .content-wrapper h2 {
      font-size: 18px;
      padding-left: 10px;
    }
  
    .text-section h2 {
      font-size: 20px;
    }
  
    .text-section p {
      font-size: 14px;
    }
  
    .text-section .date,
    .text-section .read-more {
      font-size: 12px;
    }
  
    .background-image {
      width: 100%;
      height: auto;
      margin-top: 20px;
    }
  }
