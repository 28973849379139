@font-face {
    font-family: 'Graphik';
    src: url('../../fonts/GraphikRegular.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'GraphikLight';
    src: url('../../fonts/GraphikLight.otf') format('opentype');
}

.main {
    width: 100%;
    overflow-x: hidden;
  }
  
  .Team-container {
    width: 100%;
    height: 350px;
    background-size: cover;
    background-position: auto;
    position: relative;
    color: white;
    font-family: 'Graphik', sans-serif;
    overflow: hidden;
  }
  
  .Team-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #005288 30%, #f85f36 100%);
    opacity: 0.8;
  }
  
  .content-Team {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    width: 100%;
  }
  
  .content-Team h1 {
    font-size: 36px;
    margin-bottom: 10px;
    margin-top: 150px;
    color: white;
    padding-left: 50px;
  }
  
  .table-wrapper {
    width: 92.5%;
    max-width: 1200px;
    margin: 50px auto;
    overflow-x: auto;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    font-family:'Graphik', sans-serif;
    box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.1);
  }
  
  .custom-table th,
  .custom-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    white-space: nowrap;
  }

  .row-link{
    color: black;
  }
  
  .custom-table th {
    background-color: rgb(165, 215, 235);
    font-weight: bold;
  }
  
  .custom-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .custom-table tr:hover {
    background-color: #f5f5f5;
  }
  
  @media screen and (max-width: 1024px) {
    .table-wrapper {
      width: 95%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .custom-table {
        font-size: 14px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .Team-container {
      height: 250px;
    }
  
    .content-Team h1 {
      font-size: 28px;
      margin-top: 100px;
      padding-left: 20px;
    }
  
    .table-wrapper {
      width: 98%;
      margin: 30px auto;
    }
  
    .custom-table th,
    .custom-table td {
      padding: 8px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .Team-container {
      height: 200px;
    }
  
    .content-Team h1 {
      font-size: 24px;
      margin-top: 80px;
      padding-left: 15px;
    }
  
    .custom-table {
      font-size: 14px;
      margin-left: 10px;
      margin-right: 10px;
    }
  
    .custom-table th,
    .custom-table td {
      padding: 6px;
    }
  }
  
  @media screen and (max-width: 380px) {
    .custom-table {
      font-size: 12px;
      margin-left: 10px;
      margin-right: 10px;
    }
  
    .custom-table th,
    .custom-table td {
      padding: 4px;
    }
  }