@font-face {
    font-family: 'Graphik';
    src: url('../../fonts/GraphikRegular.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'GraphikLight';
    src: url('../../fonts/GraphikLight.otf') format('opentype');
  }
.Link-contact{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Train-container {
    width: 100%;
    height: 350px;
    background-size:cover;
    background-position: auto;
    position: relative;
    color: white;
    font-family:'Graphik', sans-serif;
    overflow: hidden;
  }
  
  .overlay1213 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #005288 30%, #f85f36 100%);
    opacity: 0.8;
  }
  
  .content-Train{
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
  } 
  
  .content-Train h1 {
    font-size: 36px;
    margin-bottom: 10px;
    margin-top: 170px;
    color: white;
    padding-left: 50px;

  }

  .content-contain p{
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 30px;
    text-align: justify;
    color: #003366;
    font-family:'Graphik', sans-serif;
    font-size: 20px;
    margin-bottom: 0px;
    
  }

  .description678 {
    color: #333333;
    font-size: 18px;
    line-height: 1.5;
    padding-left: 60px;
    padding-right: 60px;
    text-align: justify;
    padding-right: 60px;
  }

  .faq-button345 {
    background-color: #f85f36;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.2em;
    cursor: pointer;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
    max-width: 290px;
    margin-left: 500px;
  }
  
  .faq-button345 a {
    color: white;
    text-decoration: none;
  }
  
  .faq-button345:hover {
    background-color: #f85f36;
  }

  .faq-button3456 {
    background-color: #f85f36;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.2em;
    cursor: pointer;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
    max-width: 290px;
    margin-left: 500px;
  }
  
  .faq-button3456 a {
    color: white;
    text-decoration: none;
  }
  
  .faq-button3456:hover {
    background-color: #f85f36;
  }

  .Second-container12 h2{
    color: #003366;
    font-size: 26px;
    font-weight: bold;
    padding-top: 20px;
    padding-left: 60px;
  }

  .gs1-banner234 {
    background-color: white;
    padding: 20px;
    padding-left: 60px;
    font-family:'Graphik', sans-serif;
    padding-top: 5px;
    
  }
  
  .gs1-benefits {
    list-style-type: none;
    padding: 0;
  }
  
  .gs1-benefits li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #333333;
    font-size: 18px;
    gap:10px;
    line-height: 10px;
    /* text-align: justify; */
  }
  
  .check-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .courses123 h2{
    color: #003366;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 15px;
    padding-left: 60px;
  }

  .courses123 p{
    color: #333333;
    display: flex; 
    font-size: 18px;
    line-height: 1.5;
    padding-left: 60px;
    text-align: justify;
    padding-right: 60px;
    
  }

  @media (max-width: 768px) {
    .Train-container {
      height: 250px;
    }
  
    .content-Train h1 {
      font-size: 28px;
      margin-top: 100px;
      
    }
  
    .content-contain p,
    .description678,
    .Second-container12 h2,
    .gs1-banner234,
    .courses123 h2,
    .courses123 p {
      padding-left: 20px;
      padding-right: 20px;
      font-size: 16px;
    }
  
    .faq-button345 {
      margin-left: auto;
      margin-right: auto;
    }
    .faq-button3456 {
        margin-left: 90px;
        margin-right: auto;
      }
  
    .gs1-benefits li {
      font-size: 16px;
      line-height: 1.25;
    }
  }
  
  @media (max-width: 480px) {
    .Train-container {
      height: 200px;
    }
  
    .content-Train h1 {
      font-size: 24px;
      margin-top: 80px;
      padding-left: 15px;
    }
  
    .content-contain p,
    .description678,
    .Second-container12 h2,
    .gs1-banner234,
    .courses123 h2,
    .courses123 p {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 14px;
    }
  
    .gs1-benefits li {
      font-size: 14px;
    }
  }