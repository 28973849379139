@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.gs1-board-of-directors-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem;
  font-family: 'Poppins', sans-serif;
  color: #333;
  line-height: 1.6;
  margin-top: 50px;
  transition: opacity 1.3s ease;
  animation-delay: 1.2s;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.gs1-board-of-directors-header {
  text-align: center;
  margin-bottom: 3rem;
}

.gs1-board-of-directors-header h1 {
  font-size: 3rem;
  color: #1e2a6e;
  border-bottom: 3px solid #f85f36;
  display: inline-block;
  padding-bottom: 10px;
  margin-top: 15px;
  animation: fadeInDown 1.2s ease-out;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

.gs1-board-of-directors-section {
  margin-bottom: 4rem;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.gs1-board-of-directors-section.animate {
  opacity: 1;
  transform: translateY(0);
}

.gs1-board-of-directors-section h2 {
  font-size: 2.2rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  position: relative;
  padding-left: 45px;
}

.gs1-board-of-directors-section p {
  font-size: 1.1rem;
  color: #34495e;
}

.icon-wrapper {
  position: absolute;
  left: 20px;
  top: 85px;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: #f85f36;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.section-icon {
  font-size: 1.5rem;
  color: white;
}

.gs1-board-of-directors-section:hover .icon-wrapper {
  transform: translateY(-50%) scale(1.1);
}

.team-section {
  text-align: center;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.team-member {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  transform-style: preserve-3d;
  perspective: 1000px;
}

.team-member:hover {
  transform: translateY(-15px) rotateY(10deg);
}

.profile-image-wrapper {
  perspective: 1000px;
}

.profile-image {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
  border: 4px solid #f85f36;
  transition: all 0.5s ease;
  transform-style: preserve-3d;
}

.team-member:hover .profile-image {
  transform: rotateY(180deg);
}

.team-member h3 {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  color: #2c3e50;
}

.team-member p {
  font-size: 1rem;
  color: #7f8c8d;
}

.values-list {
  list-style-type: none;
  padding-left: 0;
}

.values-list li {
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative;
}

.values-list li::before {
  content: '•';
  color: #f85f36;
  font-size: 1.5em;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .gs1-board-of-directors-header h1 {
    font-size: 2.5rem;
  }

  .gs1-board-of-directors-section h2 {
    font-size: 2rem;
  }

  .gs1-board-of-directors-section p {
    font-size: 1rem;
  }

  .team-grid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }

  .profile-image {
    width: 120px;
    height: 120px;
  }

  .icon-wrapper {
    position: absolute;
    left: 20.5px;
    top: 80px;
    transform: translateY(-50%);
    background-color: #f85f36;
  }
}

@media (max-width: 480px) {
  .gs1-board-of-directors-container {
    padding: 1.5rem;
  }

  .gs1-board-of-directors-header h1 {
    font-size: 2rem;
  }

  .gs1-board-of-directors-section h2 {
    font-size: 1.75rem;
  }

  .team-grid {
    grid-template-columns: 1fr;
  }
}