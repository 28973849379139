@font-face {
  font-family: Hagrid;
  src: url('../../../fonts/Hagrid-Regular-trial.ttf');
}

@font-face {
  font-family: Graphik;
  src: url('../../../fonts/GraphikRegular.otf');
}

@font-face {
  font-family: GraphikLight;
  src: url('../../../fonts/GraphikLight.otf');
}
.main{
  display: flex;
  flex-direction: column;
}
.mainRetail{
  display: flex;
  flex-direction: row;
}
.logistics-banner {
  background-image: url('../../../assets/logistics.jpg'); /* Replace with your actual image path */
  background-size: cover;
  background-position: center;
  height: 400px; /* Adjust based on your needs */
  display: flex;
  align-items: center;
  padding: 0 50px;
  position: relative;
}

.logistics-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity as needed */
}

.banner-content {
  position: relative;
  z-index: 1;
  color: white;
  max-width: 50%;
}

.banner-content h1 {
  font-size: 48px;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: Graphik;
  color: white;
}

.banner-content p {
  font-size: 18px;
  line-height: 1.5;
  font-family: Graphik;
}
.logistics-container {
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
 
}

.logisticsicon {
  flex: 0 0 auto;
  margin-right: 20px;
}

.logisticsicon svg {
  align-self: flex-start;
  color: #003087; /* Adjust color as needed */
}

.text-content {
  flex: 1;
}

.text-content h2 {
  font-size: 20px; /* Adjust size as needed */
  font-weight: normal;
  margin-bottom: 10px;
  line-height: 1.5;
  width: 70%;
  font-family: Graphik;
}

.text-content p {
  font-size: 16px; /* Adjust size as needed */
  margin-bottom: 0;
  line-height: 1.5;
  color: #555;
  width: 70%;
  font-family: GraphikLight;
}
.standards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 20px 50px; */
  /* padding: 20px; */
  margin: 20px 20px;
  /* background-color: #f9f9f9; */
  /* border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  
}

.image-section {
  /* display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; */
}

.image-section img {
  width: 500px;
  /* max-width: 600px; Adjust size as needed */
  height: auto;
}

.content-section {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  margin-bottom: 20px;
}

.content-section h3 {
  font-size: 24px; /* Adjust size as needed */
  color: #003087; /* Adjust color as needed */
  margin: 0;
}

.content-section button {
  background-color: #f85f36; /* Adjust color as needed */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px; /* Adjust size as needed */
  cursor: pointer;
}

.content-section button:hover {
  background-color: #f85f36; /* Adjust hover color as needed */
}

.description-section {
  text-align: left;
  padding: 0 0px;
}

.description-section h2 {
  font-size: 24px; /* Adjust size as needed */
  color: #003087; /* Adjust color as needed */
  margin-bottom: 10px;
}

.description-section p {
  font-size: 16px; /* Adjust size as needed */
  color: #555;
  line-height: 1.5;
  width: 60%;
}

.video-background-container {
  position: relative;
  width: 100%;
  height: 70vh; /* Full viewport height */
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}

.blue-overlay123 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Blue with 30% opacity */
  z-index: 1;
}

.content_Video {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.content_Video h1 {
  color: white;
  font-size: 4rem;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

/* Tablet styles */
@media screen and (max-width: 1024px) {
  .logistics-banner {
    height: 300px;
    padding: 0 30px;
  }

  .banner-content {
    max-width: 70%;
  }

  .banner-content h1 {
    font-size: 36px;
  }

  .banner-content p {
    font-size: 16px;
  }

  .logistics-container {
    padding: 20px 30px;
  }

  .text-content h2,
  .text-content p {
    width: 90%;
  }

  .standards-container {
    margin: 20px 10px;
  }

  .image-section img {
    width: 100%;
    max-width: 400px;
  }

  .description-section p {
    width: 80%;
  }

  .video-background-container {
    height: 50vh;
  }

  .content_Video h1 {
    font-size: 3rem;
  }
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .logistics-banner {
    height: 250px;
    padding: 0 20px;
  }

  .banner-content {
    max-width: 100%;
  }

  .banner-content h1 {
    font-size: 28px;
  }

  .banner-content p {
    font-size: 14px;
  }

  .logistics-container {
    padding: 20px;
  }

  .text-content h2,
  .text-content p {
    width: 100%;
  }

  .standards-container {
    margin: 20px 0;
  }

  .image-section img {
    max-width: 300px;
  }

  .content-section {
    flex-direction: column;
    gap: 15px;
  }

  .description-section {
    padding: 0 20px;
  }

  .description-section p {
    width: 100%;
  }

  .video-background-container {
    height: 40vh;
  }

  .content_Video h1 {
    font-size: 2.5rem;
  }

  .mainRetail {
    flex-direction: column;
  }
}

/* Extra small devices */
@media screen and (max-width: 480px) {
  .banner-content h1 {
    font-size: 24px;
  }

  .text-content h2 {
    font-size: 18px;
  }

  .text-content p {
    font-size: 14px;
  }

  .content-section h3 {
    font-size: 20px;
  }

  .description-section h2 {
    font-size: 20px;
  }

  .description-section p {
    font-size: 14px;
  }

  .content_Video h1 {
    font-size: 2rem;
  }
}