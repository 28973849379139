.case-studies {
  font-family: Graphik, sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.case-studies h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.case-studies-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

.case-study-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.case-study-image {
  width: 100%;
  height: 150px;
  object-fit: contain;
}

.case-study-content {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.case-study-category {
  color: #f85f36;
  font-weight: bold;
  margin-bottom: 10px;
}

.case-study-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.case-study-description {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
  flex-grow: 1;
}

.case-studies-button {
  background-color: #003399;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  display: block;
  width: fit-content;
  margin-left: 500px;
}

.case-studies-button:hover {
  background-color: #002266;
}

@media (max-width: 768px) {
  .case-studies h2 {
    font-size: 20px;
  }

  .case-studies-grid {
    grid-template-columns: 1fr;
  }

  .case-study-title {
    font-size: 16px;
  }

  .case-study-description {
    font-size: 14px;
  }

  .case-studies-button {
    width: 100%;
    text-align: center;
  }
}