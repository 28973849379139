.Team2-container {
    width: 100%;
    height: 350px;
    background-position: auto;
    position: relative;
    color: white;
    font-family: 'Graphik', sans-serif;
    overflow: hidden;
  
  }
.Team2-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #005288 30%, #f85f36 100%);
    opacity: 0.8;
  }
.content2-Team {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    width: 100%;
  }
  
.content2-Team h1 {
    font-size: 36px;
    margin-bottom: 10px;
    margin-top: 150px;
    color: white;
    padding-left: 50px;
  }
  .Update-button {
    background-color: #f85f36;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.2em;
    cursor: pointer;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 1s ease;
    animation-delay: 1s;
    animation: fadeInUp 1s ease-out forwards;
    transition: opacity 1s ease;
    margin-top: 5%;
  }
  .Update-button  a {
    color: white;
    text-decoration: none;
  }

.container9 {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    margin-top: 20px;
  }
  
  .intro9-text, .info-text {
    color: #00345f;
    line-height: 1.5;
  }
  
  .feature9-list {
    color: #00345f;
    padding-left: 20px;
  }
  
  .feature9-list li {
    margin-bottom: 10px;
  }
  
  .update9-question {
    color: #f85f36;
    font-size: 24px;
    margin-top: 30px;
  }
  
  .button9-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .update9-button {
    background-color: #ffffff;
    border: 1px solid #00345f;
    color: #00345f;
    padding: 15px;
    width: 48%;
    text-align: left;
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }
  
  .button9-subtext {
    color: #3498db;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .update9-button:hover {
    background-color: #f0f0f0;
  }