@font-face {
    font-family: 'Graphik';
    src: url('../../fonts/GraphikRegular.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'GraphikLight';
    src: url('../../fonts/GraphikLight.otf') format('opentype');
  }
  
  .GS1-container {
      width: 100%;
      height: 350px;
      background-size:cover;
      background-position: auto;
      position: relative;
      color: white;
      font-family:'Graphik', sans-serif;
      overflow: hidden;
    }
    
    .lay2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(135deg, #005288 30%, #f85f36 100%);
      opacity: 0.8;
    }
    
    .content-GS1 {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 2;
    } 
    
    .content-GS1 h1 {
      font-size: 36px;
      margin-bottom: 10px;
      margin-top: 110px;
      color: white;
      text-align: left;
      padding-left: 50px;

    }
    
    .content-GS1 h2 {
      font-size: 24px;
      font-weight: normal;
      padding-left: 50px;
      color: white;
    }

    .Why h1{
      padding-left: 50px;
      margin-top: 30px;
      text-align: left;
    }

    body {
      background-color: white;
      font-family: 'Graphik', sans-serif;
    }

    
    .info-container {
      display: flex;
      flex-direction: column;
      gap: 50px;
      margin-top: 50px;
      margin-bottom: 50px;
      padding-left: 50px;
      padding-right: 30px;

    }
    
    .info-item1 {
      display: flex;
      align-items: flex-start;
      gap: 20px;
    }
    
    .icon-bar {
      width: 40px;
      height: 40px;
      color: #f85f36;
      flex-shrink: 0;
      
    }
    
    .text-content {
      flex-grow: 1;
    }
    
    .text-content h3 {
      color: #003366;
      margin: 0 0 10px 0;
      font-size: 18px;
      
    }
    
    .text-content p {
      margin: 0;
      font-size: 16px;
      line-height: 1.4;
      color: #333;
      width: 100%;
      
    }

    @media (max-width: 768px) {
      .content-GS1 h1,
      .content-GS1 h2{
        padding-left: 10px;
      }
      .Why h1 {
        padding-left: 20px;
      }
    
      .info-container {
        padding-left: 20px;
        padding-right: 20px;
      }
    
      .info-item {
        flex-direction: row;
        align-items: flex-start;
      }
    
      .icon-wrapper9 {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }
    
      .text-content {
        display: flex;
        flex-direction: column;
      }
    
      .text-content h3 {
        font-size: 16px;
        margin-bottom: 5px;
      }
    
      .text-content p {
        font-size: 14px;
        margin-top: 0;
      }
    }
    
    @media (max-width: 480px) {
      .content-GS1 h1 {
        font-size: 28px;
      }
    
      .content-GS1 h2 {
        font-size: 20px;
      }
    
      .text-content h3 {
        font-size: 14px;
      }
    
      .text-content p {
        font-size: 12px;
        text-align: justify;
        padding-left: 0px;
      }
    }