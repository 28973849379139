.video-section {
    display: flex;
    gap: 20px;
    justify-content: center;
    padding: 20px;
  }
  
  .video-thumbnail {
    position: relative;
    width: 300px; /* Adjust width as needed */
    height: 200px; /* Adjust height as needed */
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
  }
  
  .play-button img {
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
  }
  
  .video-title {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
  }
  