@font-face {
    font-family: Hagrid;
    src: url('../../../../fonts/Hagrid-Regular-trial.ttf');
  }
  
  @font-face {
    font-family: Graphik;
    src: url('../../../../fonts/GraphikRegular.otf');
  }
  
  @font-face {
    font-family: GraphikLight;
    src: url('../../../../fonts/GraphikLight.otf');
  }
.gs1-standards {
    font-family: Graphik;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .gs1-standards h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .gs1-standards p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
    font-family: GraphikLight;
  }
  
  .gs1-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
  }
  
  .gs1-links {
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .gs1-links-item {
    padding: 10px;
    text-align:left;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    font-family: GraphikLight;
  }
  
  .gs1-links-item.blue { background-color: #9ce4f4; color: black }
  .gs1-links-item.green { background-color: #cce4ac; color: black }
  .gs1-links-item.pink { background-color: #f4b4cc; color: black }
  .gs1-links-item.yellow { background-color: #fce4a4; color: black; }
  .gs1-links-item.red { background-color: #acd8fc; color: black }
  .gs1-links-item.purple { background-color: #eccce4; color: black }
  
  .gs1-sidebar {
    flex: 1;
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 4px;
  }
  
  .gs1-sidebar-header {
    background-color: #0065a4;
    color: white;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .gs1-sidebar h3 {
    font-size: 20px;
    font-weight: bold;
    font-family: Graphik;
  }
  
  .gs1-sidebar ul {
    list-style-type: none;
    padding: 0;
    font-family: GraphikLight;
  }
  
  .gs1-sidebar ul li {
    font-size: 16px;
    margin-bottom: 5px;
  }
  