@font-face {
  font-family: 'Graphik';
  src: url('../../fonts/GraphikRegular.otf') format('opentype');
}

@font-face {
  font-family: 'GraphikLight';
  src: url('../../fonts/GraphikLight.otf') format('opentype');
}


.about-us-container {
    width: 100%;
    height: 350px;
    background-size: cover;
    background-position: center;
    position: relative;
    color: white;
    font-family:'Graphik', sans-serif;
    overflow: hidden;
  }
  
  .overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(49, 49, 131, 0.8) 50%, transparent 100%);
  }
  
  .content-wrapper {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
  }
  
  
  .content-wrapper h1 {
    font-size: 36px;
    margin-bottom: 10px;
    margin-top: 110px;
    color: white;
    padding-left: 50px;
    animation-delay: 0.3s;
    animation: fadeInUp 0.6s ease-out forwards;
    transition: opacity 0.3s ease;
    text-align: left;
  }
  
  .content-wrapper h2 {
    font-size: 24px;
    font-weight: normal;
    padding-left: 50px;
    color: white;
    animation-delay: 0.3s;
    animation: fadeInUp 0.6s ease-out forwards;
    transition: opacity 0.3s ease;
    text-align: left;
  }


  .common-language-container {
    font-family: 'Graphik', sans-serif;
    margin: 0 auto;
    padding: 20px;
    
  }
  
  .title {
    color: #003366;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 15px;
    padding-left: 40px;
  }
  
  .description {
    color: #333333;
    font-size: 18px;
    line-height: 1.5;
    padding-left: 40px;
    text-align: justify;
    padding-right: 40px;
  }

  .bullet-container{
    background-color:  #E5EFF3;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  }
  
  .title1 {
    color: #003366;
    font-size: 18px;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    margin-top: 5px;
    width: 250px;
    justify-content: center;
    padding-left: 80px;
    padding-right: 100px;
    gap:100px;
    text-align: center;
  }
  
  .description1{
    color: #333333;
    font-size: 16px;
    line-height: 1.5; 
    margin: 0; 
    width: 250px;
    justify-content: center;
    padding-left: 80px;
    padding-right: 100px;
    display:flex;
    text-align: center;
    gap:100px;
  }

  .ceo-quote-container {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Graphik', sans-serif;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .ceo-image-container {
    flex: 0 0 30%;
    margin-right: 2rem;
  }
  
  .ceo-image {
    width: 70%;
    height: auto;
    border-radius: 5px;
  }
  
  .quote-content {
    flex: 1;
    position: relative;
  }
  
  .quote-marks {
    font-size: 6rem;
    color: #f85f36;
    position: absolute;
    top: -3rem;
    left: -2rem;
    font-family: 'Graphik', sans-serif;
  }
  
  .quote-text {
    font-size: 1.5rem;
    color: #003366;
    line-height: 1.4;
    margin-bottom: 1rem;
    padding-right: 40px;
  }
  
  .quote-author {
    font-size: 1rem;
    color: black;
    font-weight: bold;
  }

  .gs1-container {
    display: flex;
    align-items: center;
    padding: 20px;
    width: 1100px;
    position: relative;
    margin-bottom: 30px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

  }
  
  .gs1-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 900px;
    width: 100%;
    padding-left: 5px;
    justify-content: center;
  }
  
  .gs1-heading {
    font-family: 'Graphik', sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #003366;
    margin-top: 0;
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: 2px solid #e0e0e0;
    padding-left: 20px;
  }
  
  .gs1-content {
    font-family: 'Graphik', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333333;
    margin: 0;
    padding-left: 20px;
  }

  @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

.success-keys-container {
  background-color: #E5EFF3;
  padding: 40px 20px;
  font-family: 'Graphik', sans-serif;
}

.success-keys-heading {
  color: #003366;
  font-size: 28px;
  margin-bottom: 30px;
  text-align: center;
}

.success-keys-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.success-key {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  width: 200px;
  text-align: center;
}

.icon-circle {
  color: white;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.icon-circle i {
  color: #f85f36;
  font-size: 32px;
}

.success-key p {
  color: #333;
  font-size: 16px;
  line-height: 1.4;
  margin: 0;
  text-align: center;
}

@media (max-width: 1024px) {
  .success-keys-heading {
    font-size: 24px;
  }

  .success-key {
    width: 150px;
  }

  .icon-circle {
    width: 60px;
    height: 60px;
  }

  .icon-circle i {
    font-size: 24px;
  }

  .success-key p {
    font-size: 14px;
  }
  .gs1-container{
    width: 90%;
  }
  .quote-author{
    padding-left: 20px;
  }

  .description16{
    margin-bottom: 25px;
  }

  .quote-marks{
    padding-left: 20px;
  }
}

@media (max-width: 480px) {
  .success-keys-grid {
    flex-direction: column;
    align-items: center;
  }

  .success-key {
    width: 100%;
  }
  .description16{
    margin-bottom: 25px;
  }
  .content-wrapper h1{
    text-align: left;
    padding: 0%;
    margin-top: 70px;
  }
  .content-wrapper h2{
    text-align: left;
    padding: 0%;
    margin-top: 10px;
  }
  .title121{
    padding-left: 10px;
    padding-right: 10px;
  }
  .description121{
    padding-left: 10px;
    padding-right: 10px;
  }
  .logo-container{
    height: 70px;
  }
}
  
  @media (max-width: 600px) {
    .gs1-container{
      width: 90%;;
    }
    .gs1-card {
      padding: 16px;
    }
  
    .gs1-heading {
      font-size: 20px;
    }
  
    .gs1-content {
      font-size: 14px;
      text-align: justify;
    }
  }
  
  @media (max-width: 768px) {
    .ceo-quote-container {
      flex-direction: column;
      padding: 1rem;
    }
  
    .ceo-image-container {
      margin-right: 0;
      margin-bottom: 1rem;
      flex: 0 0 100%;
      text-align: center;
    }
  
    .quote-marks {
      font-size: 4rem;
      top: -2rem;
      left: -1rem;
    }
  
    .quote-text {
      font-size: 1.2rem;
      padding-left: 20px;
      text-align: justify;
    }
  }
  
  @media (max-width: 480px) {
    .quote-marks {
      font-size: 3rem;
      top: -1.5rem;
      left: -0.5rem;
    }
  
    .quote-text {
      font-size: 1rem;
    }
  
    .quote-author {
      font-size: 0.9rem;
      padding-left: 20px;
    }
  }
  
