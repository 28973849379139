.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: white;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    max-width: 100%;
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
   
  }
  
  .popup h2 {
    color: #333;
    margin-top: 0;
  }
  
  .popup p {
    color: #666;
  }
  
  .popup button {
    background-color: #f85f36;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .popup button:hover {
    background-color: #f85f36;
  }